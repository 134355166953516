<template>
  <div class="register">
    <Title :title="$t('9cc4fed')" class="title" />
    <Steps :active="stepsActive" />
    <EnterpriseForm
      v-show="stepsActive === 0"
      ref="form1"
      class="form"
      :steps-active="stepsActive"
      @nextStep="nextStep"
    />
    <CommonForm
      v-show="stepsActive === 1"
      ref="form2"
      class="form"
      :register-type="registerType"
      @lastStep="lastStep"
      @submitForm="submitForm"
    />

    <Success v-show="stepsActive === 2" />
  </div>
</template>

<script>
import Steps from '@/components/Steps'
import EnterpriseForm from '../components/EnterpriseForm'
import CommonForm from '../components/CommonForm'
import Success from '../components/Success'
import Title from '@/components/Title'
// import { mapGetters } from 'vuex'
import API from '@/api'
import { log } from '@/utils/helper'
export default {
  name: 'Enterprise',
  components: {
    Title,
    Steps,
    EnterpriseForm,
    CommonForm,
    Success,
  },
  data() {
    return {
      stepsType: 'enterprise',
      registerType: 'Organization',
      stepsActive: 0,
    }
  },
  computed: {
    // ...mapGetters(['test']),
  },
  watch: {
    stepsActive(newVal) {
      if (newVal === 2) {
        this.goLogin()
      }
    },
  },
  created() {
    this.handleAbroad()
  },
  methods: {
    handleAbroad() {
      const isAbroad = this.$route.query.abroad === 'abroad'
      if (isAbroad) {
        this.stepsContent = {
          step1: 'Company Verification',
          step2: 'Fill in account information',
          step3: 'Complete',
        }
      }
    },
    goLogin() {
      const timeoutId = setTimeout(() => {
        clearTimeout(timeoutId)
        this.$router.push({
          path: '/account/login',
        })
      }, 3000)
    },
    submitForm() {
      const data1 = this.$refs.form1.creatData()
      const data2 = this.$refs.form2.creatData()
      const data = Object.assign(data1, data2)
      const array = Object.keys(data)
      const result = new FormData()
      for (let index = 0; index < array.length; index++) {
        const key = array[index]
        const val = data[key]
        result.append(key, val)
      }
      this.register(result)
    },
    lastStep() {
      this.changeStepsActive(0)
    },
    nextStep() {
      this.changeStepsActive(1)
    },
    changeStepsActive(index) {
      this.stepsActive = index
    },
    register(data) {
      API.account
        .enterpriseRegister(data)
        .then((res) => {
          if (res.code === '000000') {
            this.stepsActive = 2
            this.$message.success('注册成功')
          } else {
            this.$message.error(res.message)
          }
        })
        .catch(() => {
          log('Interface exception API.account.enterpriseRegister()')
        })
    },
  },
}
</script>
<style scoped lang="less">
@import '~@/styles/variables.less';
.register {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: @uni-width;
  margin: 20px auto;
  min-height: calc(100vh - 260px);
  background: #fff;
  .title {
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 40px;
  }
  .form {
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    min-width: 510px;
  }
}
</style>
