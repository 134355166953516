<template>
  <el-form
    ref="commonForm"
    :model="commonForm"
    :rules="rules"
    class="commonForm"
    label-width="200px"
  >
    <el-form-item :label="autoPrefix('7e42717')" prop="name">
      <el-input
        v-model="commonForm.name"
        :placeholder="autoPrefix('cc4e181')"
      />
    </el-form-item>
    <el-form-item :label="autoPrefix('af975db')" prop="creditCode">
      <el-input
        v-model="commonForm.creditCode"
        :placeholder="autoPrefix('af975db', 'pla')"
      />
    </el-form-item>
    <el-form-item :label="autoPrefix('1d0a243')" prop="businessLicense">
      <el-upload
        ref="upload"
        class="uploadBusinessLicense"
        :headers="headers"
        action="#"
        :limit="1"
        :on-success="onSuccess"
        :on-change="onChange"
        :on-remove="onRemove"
        :before-upload="beforeUpload"
        :accept="imageMimeType"
        :file-list="fileList"
        :auto-upload="false"
      >
        <el-button class="addButton">+ {{ autoPrefix('1c88292') }}</el-button>
        <span slot="tip" class="el-upload__tip">
          {{ autoPrefix('9674f1b') }}
        </span>
      </el-upload>
    </el-form-item>
    <div v-if="!abroad" class="provinceContainer">
      <el-form-item :label="autoPrefix('62f7e8a')" prop="province">
        <el-select
          v-model="commonForm.province"
          :placeholder="autoPrefix('8c7aeca', 'pla')"
          @change="changeProvince"
        >
          <el-option
            v-for="item in provinceList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item prop="city" label-width="0">
        <el-select
          v-model="commonForm.city"
          :placeholder="autoPrefix('8c7aeca', 'pla')"
        >
          <el-option
            v-for="item in cityList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
    </div>
    <el-form-item :label="autoPrefix('95c9900')" prop="address">
      <el-input
        v-model="commonForm.address"
        :placeholder="autoPrefix('2640133')"
      />
    </el-form-item>
    <el-form-item :label="autoPrefix('1f832ea')" prop="establishedTime">
      <el-date-picker
        v-model="commonForm.establishedTime"
        type="date"
        value-format="yyyy-MM-dd"
        :placeholder="autoPrefix('215b874')"
        :picker-options="pickerOptions"
      />
    </el-form-item>
    <el-form-item :label="autoPrefix('5c1f642')" prop="industryId">
      <el-select
        v-model="commonForm.industryId"
        :placeholder="autoPrefix('aaa01e1', 'pla')"
      >
        <el-option
          v-for="item in industryOptions"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        />
      </el-select>
    </el-form-item>
    <el-form-item :label="autoPrefix('dc23bef')" prop="scale">
      <el-select
        v-model="commonForm.scale"
        :placeholder="autoPrefix('522d731')"
      >
        <el-option
          v-for="item in scaleOptions"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        />
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-button
        type="primary"
        class="loginButton"
        @click="submitForm('commonForm')"
      >
        {{ autoPrefix('127e973') }}
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
// import { validateMobilePhone } from '@/utils/helper'
import validator, { imageMimeType, isImage } from '@/utils/validation'
import API from '@/api'
import { log } from '@/utils/helper'
const i18nPrefix = {
  label: 'account.register.EnterpriseForm.lable.',
  pla: 'account.register.EnterpriseForm.pla.',
  com: 'account.register.com.',
}
import { getToken } from '@/utils/auth'
export default {
  name: 'EnterpriseForm',
  props: {
    registerType: {
      type: String,
      default: 'PERSON',
    },
    country: {
      type: String,
      default: 'CHINA',
    },
    stepsActive: {
      type: Number,
      required: true,
    },
  },
  // components: {},
  data() {
    return {
      pickerOptions: {
        disabledDate(date) {
          const today = new Date()
          today.setHours(23)
          return date > today
        },
      },
      headers: {
        accessToken: getToken(),
      },
      imageMimeType,
      shortTips: '获取验证码',
      graphicalIndex: 0,
      commonForm: {
        name: '',
        creditCode: '',
        businessLicense: '',
        province: '',
        city: '',
        address: '',
        establishedTime: '',
        industryId: '',
        scale: '',
        // name: 'dsadsad',
        // creditCode: '400111111111111',
        // businessLicense: '',
        // province: '',
        // city: '',
        // address: 'sadsadasd',
        // establishedTime: '2021-01-01',
        // industryId: 59,
        // scale: 79,
      },
      scaleOptions: [],
      industryOptions: [],
      provinceList: [],
      cityList: [],
      fileList: [],
    }
  },
  computed: {
    abroad() {
      return this.$route.query.abroad === 'abroad'
    },
    rules() {
      return {
        name: [
          { required: true, message: this.autoPrefix('cc4e181') },
          { validator: validator.compayName },
        ],
        creditCode: [
          { required: true, message: this.autoPrefix('2e7cddc') },
          { validator: validator.creditCode_zh },
        ],
        businessLicense: [{ required: true, message: this.autoPrefix('ab9ed8d') }],
        province: [{ required: true, message: this.autoPrefix('8c7aeca') }],
        city: [{ required: true, message: this.autoPrefix('8c7aeca') }],
        address: [
          { required: true, message: this.autoPrefix('2640133') },
          { validator: validator.enterpriseAddress },
        ],
        establishedTime: [{ required: true, message: this.autoPrefix('215b874') }],
        industryId: [{ required: true, message: this.autoPrefix('aaa01e1') }],
        scale: [{ required: true, message: this.autoPrefix('522d731') }],
      }
    },
    str1() {
      return this.$t('67d14f1')
    },
    str2() {
      return this.$t('6bf96ed')
    },
  },
  watch: {
    // stepsActive(newVal) {
    //   console.log('newValnewVal', newVal)
    //   // if (newVal === 0) {
    //   //   let result = sessionStorage.getItem('enterpriseForm')
    //   //   if (result) {
    //   //     result = JSON.parse(result)
    //   //     this.commonForm = result
    //   //     this.fileList = [result.businessLicense]
    //   //   }
    //   // }
    // },
  },
  created() {
    this.handleTest()
    // this.handleStorage()
    this.getDictionaryList('INDUSTRY', 'industryOptions')
    this.getDictionaryList('SCALE', 'scaleOptions')
    this.getAreaTree()
  },

  methods: {
    handleStorage() {
      let result = sessionStorage.getItem('enterpriseForm')
      if (result) {
        result = JSON.parse(result)
        this.commonForm = result
      }
    },
    handleTest() {
      if (window.location.host === 'localhost:8080') {
        this.commonForm = {
          name: 'dsadsad',
          creditCode: '400111111111111',
          businessLicense: '',
          province: '',
          city: '',
          address: 'sadsadasd',
          establishedTime: '2021-01-01',
          industryId: 59,
          scale: 79,
        }
      }
    },
    creatData() {
      const form = this.commonForm
      const data = {
        name: form.name,
        creditCode: form.creditCode,
        file: form.businessLicense.raw,
        province: form.province,
        city: form.city,
        address: form.address,
        establishedTime: form.establishedTime,
        industryId: form.industryId,
        scale: form.scale,
      }
      return data
    },
    clearFiles() {
      this.$refs.upload.clearFiles()
      this.commonForm.businessLicense = ''
      this.$refs.commonForm.validateField('businessLicense')
    },
    onChange(file, fileList) {
      const isLt10M = file.size / 1024 / 1024 < 10
      const type = file.raw.type
      const str = this.str1
      if (!type) {
        this.$message.error(str)
        this.clearFiles()
        return false
      }
      if (!imageMimeType.includes(type)) {
        this.$message.error(str)
        this.clearFiles()
        return false
      }
      if (!isLt10M) {
        this.$message.error(this.str2)
        this.clearFiles()
        return false
      }
      this.fileList = fileList
      this.commonForm.businessLicense = file
      this.$refs.commonForm.validateField('businessLicense')
    },
    onRemove() {
      this.clearFiles()
    },
    onSuccess(response, file, fileList) {
      if (response.code === '000000') {
        this.$parent.stepsActive = 2
        this.$message.success(response.message)
        // this.commonForm.businessLicense = response.data.url
      } else {
        this.$message.error(response.message)
        // this.commonForm.businessLicense = ''
      }
    },
    beforeUpload(file) {
      console.log('beforeUpload', file)
      const isLt10M = file.size / 1024 / 1024 < 10
      if (!isImage(file.type)) {
        this.$message.error(this.str1)
        return false
      }
      if (!isLt10M) {
        this.$message.error(this.str2)
      }
      return isLt10M
    },
    changeProvince(val) {
      this.commonForm.city = ''
      if (val) {
        for (let index = 0; index < this.provinceList.length; index++) {
          const element = this.provinceList[index]
          if (element.id === val) {
            this.cityList = element.children
            break
          }
        }
      }
    },
    getAreaTree() {
      API.common
        .getAreaTree()
        .then((res) => {
          if (res && res.content) {
            this.provinceList = res.content
          }
        })
        .catch(() => {
          log('Interface exception API.common.getAreaTree()')
        })
    },
    getDictionaryList(type, options) {
      API.common
        .getDictionaryList({ type })
        .then((res) => {
          if (res && res.content) {
            this[options] = res.content
          }
        })
        .catch(() => {
          log('Interface exception API.common.getDictionaryList()')
        })
    },
    autoPrefix(name) {
      let fun = '$t'
      if (this.abroad) {
        fun = 'geEn'
      }
      return this[fun](name)
    },
    geEn(key) {
      const i18n = this.$i18n
      return i18n._getMessages().en[key] || key
    },
    handleSubmitForm() {
      this.$emit('nextStep')
      sessionStorage.setItem('enterpriseForm', JSON.stringify(this.commonForm))
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.handleSubmitForm()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // resetForm() {
    //   this.$refs['commonForm'].resetFields()
    // },
  },
}
</script>

<style scoped lang="less">
@import '~@/styles/variables.less';
// @import '~@/styles/common.less';
.commonForm {
  & /deep/ .el-form-item {
    margin-bottom: 24px;
  }
  & /deep/ .loginButton {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 360px;
    height: 40px;
    background: #00a4ff;
    border-radius: 4px;
  }
  & /deep/ .el-input__inner {
    // border-radius: 23px;
  }

  & /deep/ .el-cascader,
  .el-select,
  .el-date-editor {
    width: 100%;
  }
  & /deep/ .el-form-item__content {
    width: 360px;
  }
  .uploadBusinessLicense {
    & /deep/ .el-button {
      margin-right: 20px;
    }
    .el-upload__tip {
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
    }
  }
  .provinceContainer {
    display: flex;
    & /deep/ .el-form-item__content {
      width: 180px;
    }
  }
  .graphical {
    position: relative;
    .uiImg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      width: 80px;
      height: 34px;
      padding: 4px 0 2px 10px;
      margin-right: 10px;
      border-left: 2px solid #efefef;
      cursor: pointer;
    }
  }
  .short {
    position: relative;
    .uiText {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      padding: 0 10px;
      border-left: 2px solid #efefef;
      font-size: 14px;
      color: #cccccc;
      text-align: center;
      cursor: pointer;
    }
  }

  .agreement {
    & /deep/ .el-checkbox__label {
      display: inline-grid;
      white-space: pre-line;
      word-wrap: break-word;
      overflow: hidden;
      line-height: 20px;
    }
  }
}
</style>
